<template>
  <div>
    <v-app-bar app color="indigo" dark dense>
      <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer" /> -->

      <v-toolbar-title style="width: 50px;">
        <router-link style="color: white; text-decoration: none;" to="/"
          >{{getAppName}}</router-link
        >
      </v-toolbar-title>
      <!-- <div class="mx-4"></div> -->
      <v-btn v-if="isHaus" class="ma-2" text to="/mieter">Mieter</v-btn>
      <v-btn v-if="isSpende || isHerausgeber" class="ma-2" text to="/kontakt">{{getKontaktMenuTitle}}</v-btn>
      <v-btn v-if="isVerlag" class="ma-2" text to="/kontakt-verlag">Abonnenten</v-btn>      
      <v-btn v-if="isVerlag || isHerausgeber" class="ma-2" text to="/kontakt-import">Änderungsdienst</v-btn>      
      <v-btn v-if="isVerlag" class="ma-2" text to="/verlag-export">Logistik</v-btn>            
      <v-btn v-if="isVerlag" class="ma-2" text to="/verlag-ausgabe">Ausgaben</v-btn>      
      <v-btn v-if="isUserBuch && isSpende" class="ma-2" text to="/projekt">Projekte</v-btn>
      <v-btn v-if="isUserBuch && !isHerausgeber && !isVerlag" class="ma-2" text to="/lieferant">Lieferanten</v-btn>      
      <v-btn v-if="isUserBuch && !isHerausgeber && !isVerlag" class="ma-2" text to="/konto">Konten</v-btn>
      <v-btn v-if="isUserBuch && !isHerausgeber && !isVerlag" class="ma-2" text to="/buch">Buchungen</v-btn>
      <v-btn v-if="isUserBuch && isSpende" class="ma-2" text to="/quitt">Quittungen</v-btn>
      <v-btn v-if="isUserBuch && isSpende" class="ma-2" text to="/aktion">Aktionen</v-btn>
      <v-btn v-if="isHaus" class="ma-2" text to="/wohnung">Wohnungen</v-btn>            

      <v-menu  v-if="isUserBuch && (isSpende || isHaus)" open-on-hover left bottom :offset-y="true">
        <template v-slot:activator="{ on }">
          <v-btn class="ma-2" text v-on="on">
            Bank
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="isUserBuch" to="/bankumsatz"
            >Umsätze</v-list-item
          >
          <v-list-item v-if="isUserBuch" to="/bankauftrag"
            >Aufträge</v-list-item
          >
        </v-list>
      </v-menu>

      <v-spacer></v-spacer>

      <v-overflow-btn
        style="width: 200px"
        class="my-2"
        :items="mandanten"
        @change="changeMandant"
        v-model="selectedMandant"
        hide-details
        dense
      ></v-overflow-btn>

      <div class="mx-1"></div>

      <v-menu v-if="getSettingsAvailable" open-on-hover left bottom :offset-y="true">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="isUserKonti" to="/konti-export"
            >Konti-Export</v-list-item
          >
          <v-list-item v-if="isUserBuch" to="/buch-export"
            >Buchungsexport</v-list-item
          >
          <v-list-item v-if="isUserAdmin" @click.stop="showEbYearForm = true"
            >Jahresüberträge</v-list-item
          >
          <v-list-item v-if="isUserAdmin" to="/sachkonto"
            >Sachkonten</v-list-item
          >
          <v-list-item v-if="isUserAdmin" to="/bankkonto"
            >Bankkonten</v-list-item
          >
          <v-list-item v-if="isUserAdmin" @click="syncKontoDms"
            >Kontenübertragung DMS</v-list-item
          >
          <v-list-item
            v-if="isKstTraegerStelle && isUserAdmin"
            to="/kostenstelle"
            >Kostenstellen</v-list-item
          >
          <v-list-item
            v-if="isUserAdmin"
            @click.stop="showMandantEditForm = true"
            >Einstellungen</v-list-item
          >
        </v-list>
      </v-menu>

      <v-btn icon @click.native="logout">
        <v-icon>logout</v-icon>
      </v-btn>
      <MandantEditForm v-model="showMandantEditForm" />
      <EbYearForm v-model="showEbYearForm" />
      <Confirm ref="confirm"></Confirm>
      <Progress v-model="showProgress"> </Progress>
    </v-app-bar>

    <v-snackbar color="snackcolor" v-model="snackbar" :timeout="6000">
      {{ snacktext }}
      <v-btn dark text @click="snackbar = false">Schließen</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import MandantEditForm from "./MandantEdit.vue";
import EbYearForm from "./EbYear.vue";
import Confirm from "./Confirm";
import Progress from "./Progress";

export default {
  name: "menuu",
  data: () => ({
    showProgress: false,
    snackbar: false,
    snacktext: "",
    snackcolor: "",
    mandanten: [],
    selectedMandant: null,
    showMandantEditForm: false,
    showEbYearForm: false,
  }),
  components: {
    MandantEditForm,
    EbYearForm,
    Confirm,
    Progress,
  },
  created() {
    this.initialize();
  },

  computed: {
    isKstTraegerStelle() {
      return this.$store.state.mandant.kstTraegerStelle;
    },
    isUserAdmin() {
      return this.$store.state.user.admin;
    },
    isUserBuch() {
      return this.$store.state.user.buch;
    },
    isUserKonti() {
      return this.$store.state.user.konti;
    },
    isHaus() {
      return this.$store.state.mandant.istHaus;
    },
    isSpende() {
      return this.$store.state.mandant.istSpende;
    },
    isHerausgeber() {
      return this.$store.state.mandant.istHerausgeber;
    },
    isVerlag() {
      return this.$store.state.mandant.istVerlag;
    },
    getAppName() {
      if (this.$store.state.mandant.istHerausgeber || this.$store.state.mandant.istVerlag) {
        return "ABO";
      } else {
        return "FIP"
      }
    },
    getKontaktMenuTitle() {
      if (this.$store.state.mandant.istHerausgeber || this.$store.state.mandant.istVerlag) {
        return "Abonnenten";
      } else {
        return "Kontakte"
      }
    },
    getSettingsAvailable() {
      if (this.$store.state.mandant.istHerausgeber || this.$store.state.mandant.istVerlag) {
        return false;
      } else {
        return true
      }
    },

  },

  methods: {
    initialize() {
      var i;
      for (i = 0; i < this.$mandanten.length; i++) {
        this.mandanten.push(this.$mandanten[i].name);
      }
      this.selectedMandant = this.$mandant.name;
    },

    syncKontoDms() {
      let msg =
        "Möchten Sie jetzt die Lieferanten und Aufwandskonten an das DMS übertragen?";
      this.$refs.confirm
        .open("Konten an DMS übertragen", msg, {
          width: "600px",
          color: "#1976d2",
        })
        .then((confirm) => {
          if (confirm) {
            this.showProgress = true;

            axios
              .get(this.$mandant.url + "konto/sync-dms")
              .then((res) => {
                this.showProgress = false;
                this.snacktext = "" + res.data + " Konten übertragen";
                this.snackcolor = "success";
                this.snackbar = true;
              })
              .catch((err) => {
                this.showProgress = false;
                window.console.log(err);
                this.snacktext = "Fehler";
                this.snackcolor = "error";
                this.snackbar = true;
              });
          }
        });
    },

    changeMandant(mandant) {
      this.$mandant = this.$mandanten.find((obj) => obj.name == mandant);
      axios
        .get(this.$mandant.url + "mandant")
        .then((res) => {
          this.$store.commit("mandant", res.data);
        })
        .catch((err) => window.console.log(err));
      if (this.$router.currentRoute.path !== "/{ name: 'home'}") {
        this.$router.push("{ name: 'home'}");
      }
    },

    logout() {
      let attributes = { lastMandant: [this.selectedMandant] };
      axios
        .put(this.$mandant.url + "user/attr", attributes, this.$auth)
        .then(() => {})
        .catch((err) => window.console.log(err))
        .finally(() => {
          this.$keycloak.logoutFn();
          localStorage.removeItem("vue-token");
          localStorage.removeItem("vue-refresh-token");
        });
    },
  },
};
</script>

