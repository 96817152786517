<template v-slot:activator="{ on }">
  <v-dialog v-model="show" max-width="600px" height="1000px">
    <v-card>
      <v-card-title>
        <span class="headline">Einstellungen für {{ formTitle }}</span>
      </v-card-title>

      <v-card-text style="height: 500px">
        <v-form v-model="valid">
          <v-tabs v-model="tab" background-color="transparent" grow>
            <v-tab>Finanzamt</v-tab>
            <v-tab>Auszugversand</v-tab>
            <v-tab>Email</v-tab>

            <v-tab-item>
              <v-container fluid>
                <v-text-field v-model="mandant.finanzamt" label="Finanzamt" maxlength="100" counter="100"></v-text-field>
                <v-text-field v-model="mandant.steuernr" label="Steuer Nr." maxlength="20" counter="20"></v-text-field>

                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="computedSteuerbescheidDate"
                      clearable
                      label="Datum letzter Steuerbescheid"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                      @click:clear="date = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="mandant.steuerbescheidDate"
                    no-title
                    @input="menu1 = false"
                    locale="de-de"
                  ></v-date-picker>
                </v-menu>
                <v-text-field v-if="!mandant.istStiftung" v-model="mandant.veranlagungszeitraum" label="Veranlagungszeitraum" maxlength="4" counter="4"></v-text-field>
                <v-text-field v-if="mandant.istStiftung" v-model="mandant.veranlagungszeitraumFrom" label="Veranlagungszeitraum von" maxlength="4" counter="4"></v-text-field>
                <v-text-field v-if="mandant.istStiftung" v-model="mandant.veranlagungszeitraumTo" label="Veranlagungszeitraum bis" maxlength="4" counter="4"></v-text-field>                                
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container fluid>
                <v-text-field v-model="mandant.mailFrom" label="Absender" maxlength="50" counter="50"></v-text-field>
                <v-text-field v-model="mandant.mailSubject" label="Betreffzeile" maxlength="200" counter="200"></v-text-field>
                <v-textarea
                  name="input-7-1"
                  label="Email-Text"
                  :rows="5"
                  v-model="mandant.mailContent"
                  maxlength="800" counter="800"                  
                ></v-textarea>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container fluid>
                <v-text-field v-model="mandant.mailHost" label="Host" maxlength="100" counter="100"></v-text-field>
                <v-text-field v-model="mandant.mailPort" label="Port"></v-text-field>
                <v-switch v-model="mandant.mailTls" class="mx-2" label="TLS"></v-switch>
                <v-text-field v-model="mandant.mailUser" label="Benutzername"></v-text-field>
                <v-text-field
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPass ? 'text' : 'password'"
                  label="Passwort"
                  v-model="mandant.mailPass"
                  class="input-group--focused"
                  @click:append="showPass = !showPass"
                ></v-text-field>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save">Speichern</v-btn>
        <v-btn color="blue darken-1" text @click.stop="show=false">Abbrechen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  data: () => ({
    valid: false,
    mandant: {},
    menu1: false,
    tab: null,
    showPass: false
  }),
  props: {
    value: Boolean
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      axios
        .get(this.$mandant.url + "mandant")
        .then(res => (this.mandant = res.data))
        .catch(err => window.console.log(err));
    },
    /* eslint-disable */
    save() {
      axios
        .put(this.$mandant.url + "mandant", this.mandant, this.$auth)
        .then
        //res =>
        // Object.assign(this.items[this.editedIndex], this.editedItem)
        ()
        .catch(err => window.console.log(err));
      this.show = false;
    }
    /* eslint-enable */
  },
  computed: {
    computedSteuerbescheidDate() {
      return this.mandant.steuerbescheidDate
        ? moment(this.mandant.steuerbescheidDate).format("DD.MM.YYYY")
        : "";
    },
    formTitle() {
      return this.mandant.bez;
    },
    show: {
      get() {
        this.initialize();
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>