import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        conf: {},
        mandant: {},
        user: {},
        kontaktSearch: {},
        kontaktPage:1, 
        projektSearch: {},
        projektPage:1, 
        lieferantSearch: {},
        lieferantPage:1, 
        kontoSearch: {},
        kontoPage:1, 
        buchKontoSearch: {},
        buchKontoPage:1, 
        buchSearch: {},
        buchPage:1,
        bankumsatzSearch: {},
        bankumsatzPage:1,
        bankumsatzId: 0,
        quittSearch: {},
        quittPage:1, 
        wohnungSearch: {},
        wohnungPage:1 
    },
    mutations: {
        conf(state, conf) {
            state.conf = conf
        },
        mandant(state, mandant) {
            state.mandant = mandant
        },
        user(state, user) {
            state.user = user
        },
        kontaktSearch(state, kontaktSearch) {
            state.kontaktSearch = kontaktSearch
        },
        kontaktPage(state, kontaktPage) {
            state.kontaktPage = kontaktPage
        },
        projektSearch(state, projektSearch) {
            state.projektSearch = projektSearch
        },
        projektPage(state, projektPage) {
            state.projektPage = projektPage
        },
        lieferantSearch(state, lieferantSearch) {
            state.lieferantSearch = lieferantSearch
        },
        lieferantPage(state, lieferantPage) {
            state.lieferantPage = lieferantPage
        },
        kontoSearch(state, kontoSearch) {
            state.kontoSearch = kontoSearch
        },
        kontoPage(state, kontoPage) {
            state.kontoPage = kontoPage
        },
        buchSearch(state, buchSearch) {
            state.buchSearch = buchSearch
        },
        buchPage(state, buchPage) {
            state.buchPage = buchPage
        },
        buchKontoSearch(state, buchKontoSearch) {
            state.buchKontoSearch = buchKontoSearch
        },
        buchKontoPage(state, buchKontoPage) {
            state.buchKontoPage = buchKontoPage
        },
        bankumsatzSearch(state, bankumsatzSearch) {
            state.bankumsatzSearch = bankumsatzSearch
        },
        bankumsatzPage(state, bankumsatzPage) {
            state.bankumsatzPage = bankumsatzPage
        },
        bankumsatzId(state, bankumsatzId) {
            state.bankumsatzId = bankumsatzId
        },
        quittSearch(state, quittSearch) {
            state.quittSearch = quittSearch
        },
        quittPage(state, quittPage) {
            state.quittPage = quittPage
        },
        wohnungSearch(state, wohnungSearch) {
            state.wohnungSearch = wohnungSearch
        },
        wohnungPage(state, wohnungPage) {
            state.wohnungPage = wohnungPage
        },
    },
})