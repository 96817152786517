<template>
  <div style="padding: 70px 16px">
    <v-layout row column fill-height>
      <v-flex md4 style="width: 33%;"
        ><!-- BankumsatzSaldoForm/--></v-flex
      >
      <v-flex md4></v-flex>
      <v-flex md4></v-flex>
    </v-layout>
  </div>
</template>

<script>
// import BankumsatzSaldoForm from "../components/BankumsatzSaldo";
export default {
  name: "home",
  components: {
    // BankumsatzSaldoForm,
  },
};
</script>
