<template>
  <v-app>
    <Menu v-if="!$route.meta.hideMenu" />
    <router-view/>
  </v-app>
</template>

<script>
import Menu from "./components/Menu";

export default {
  name: "App",
  components: {
    Menu
  },

  data: () => ({
    //
  })
};
</script>
