<template v-slot:activator="{ on }">
  <v-app>
    <v-dialog v-model="show" max-width="500px" height="100px">
      <loading :active.sync="isLoading" :is-full-page="true"></loading>
      <v-card>
        <v-toolbar dark color="#1976d2" dense flat>
          <v-toolbar-title class="white--text"
            >Jahresüberträge erstellen</v-toolbar-title
          >
        </v-toolbar>

        <v-card-text class="pa-4"
          >Bitte wählen Sie das Jahr aus, für das die Jahresüberträge erstellt
          werden sollen.</v-card-text
        >
        <v-card-text style="height: 50px">
          <v-form v-model="valid">
            <v-select
              style="width: 140px"
              v-model="selectedJahr"
              :items="jahrList"
              label="Jahr"
              persistent-hint
              single-line
            ></v-select>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="ok">Ok</v-btn>
          <v-btn color="blue darken-1" text @click.stop="show = false"
            >Abbrechen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Confirm ref="confirm"></Confirm>
    <Progress v-model="showProgress"> </Progress>
    <v-snackbar color="snackcolor" v-model="snackbar" :timeout="6000">
      {{ snacktext }}
      <v-btn dark text @click="snackbar = false">Schließen</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Confirm from "./Confirm";
import Progress from "./Progress";

export default {
  data: () => ({
    showProgress: false,
    snackbar: false,
    snacktext: "",
    snackcolor: "",
    valid: false,
    jahrList: [],
    selectedJahr: null,
    isLoading: false,
  }),

  components: {
    Loading,
    Confirm,
    Progress,
  },

  props: {
    value: Boolean,
    konto: {},
    jahrMonat: {},
  },

  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      axios.get(this.$mandant.url + "buch/jahr").then((res) => {
        this.jahrList = res.data;
        if (this.selectedJahr == null) {
          if (this.jahrList.length != 0) {
            this.selectedJahr = this.jahrList[0];
          }
        }
      });
    },

    ok() {
      this.show = false;
      let msg =
        "Hiermit erstellen Sie aus den Salden des Vorjahres die Jahresüberträge für das Jahr " +
        this.selectedJahr +
        ". \nBisherige automatisch erstellte Jahresüberträge werden ersetzt.\n" +
        "Möchten Sie wirklich fortfahren?";
      this.$refs.confirm
        .open("Jahresüberträge erstellen", msg, {
          width: "600px",
          color: "#1976d2",
        })
        .then((confirm) => {
          if (confirm) {
            this.showProgress = true;

            axios
              .put(this.$mandant.url + "konto/eb/" + this.selectedJahr)
              .then((res) => {
                this.showProgress = false;
                this.snacktext = "" + res.data + " Jahresübertragungen gebucht";
                this.snackcolor = "success";
                this.snackbar = true;
              })
              .catch((err) => {
                this.showProgress = false;
                window.console.log(err);
                this.snacktext = "Fehler";
                this.snackcolor = "error";
                this.snackbar = true;
              });
          }
        });

    },
  },

  computed: {
    show: {
      get() {
        this.initialize();
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>