import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import vuetify from './plugins/vuetify'
import store from './store/store'



Vue.config.productionTip = false;
Vue.prototype.$mandanten = [];
// Vue.prototype.$mandant = {};


import "@/assets/main.css"


function tokenInterceptor() {
  axios.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
    //config.headers.mandant = "afr_verein" //globalData.$data.$mandant.name;
    return config
  }, error => {
    return Promise.reject(error)
  })
}

let globalData = new Vue({
  data: { $mandant: {} }
});
Vue.mixin({
  computed: {
    $mandant: {
      get: function () { return globalData.$data.$mandant },
      set: function (newMandant) { globalData.$data.$mandant = newMandant; }
    }
  }
})

fetch('./conf.json')
  .then(function (response) {
    return response.json();
  })
  .then(function (conf) {

    Vue.use(VueKeyCloak, {
      config: {
        realm: conf.authRealm,
        url: conf.authUrl,
        clientId: conf.authClientId
      },
      onReady: () => {

        tokenInterceptor();

        // Speichern der Konfiguration im Store
        store.commit("conf", conf);

        // Einlesen der Mandanten
        var i;
        for (i = 0; i < Vue.prototype.$keycloak.idTokenParsed.mandanten.length; i++) {
          var res = Vue.prototype.$keycloak.idTokenParsed.mandanten[i].split(";");
          var mandant = { name: res[0], url: res[1] };
          Vue.prototype.$mandanten.push(mandant);
          if (mandant.name === Vue.prototype.$keycloak.idTokenParsed.lastMandant) {
            globalData.$data.$mandant = mandant;
          }
        }
        if (Vue.prototype.$keycloak.idTokenParsed.lastMandant == null) {
          globalData.$data.$mandant = Vue.prototype.$mandanten[0];
        }

        // Laden der Mandantdetails
        axios
          .get(globalData.$data.$mandant.url + "mandant")
          .then((res) => {
            store.commit("mandant", res.data);
          })
          .catch((err) => window.console.log(err));

        // Setzen der Userdaten
        let user = { buch: false, admin: false, konti: false };
        if (Vue.prototype.$keycloak.hasRealmRole('fip-admin')) {
          user.admin = true;
        }
        if (Vue.prototype.$keycloak.hasRealmRole('fip-buch')) {
          user.buch = true;
        }
        if (Vue.prototype.$keycloak.hasRealmRole('fip-konti')) {
          user.konti = true;
        }
        store.commit("user", user);

        new Vue({
          el: '#app',
          router,
          store,
          vuetify,
          render: function (h) { return h(App) }
        }).$mount('#app')      

      }
    })

    
  });




