<template>
  <v-dialog
    v-model="show"
    persistent
    width="150"
    content-class="dialog-progress"
  >
    <div class="text-center">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
        color="white"
      ></v-progress-circular>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
